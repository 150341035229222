// @flow
import * as React from 'react';
import { motion } from 'framer-motion';
import useAnimate, { fade } from 'react-hooks/useAnimate';
import type { ImageType } from 'UI/Section/Aside';

type PictureProps = {
  img: ImageType,
}

type ImageProps = {
  className?: string,
  img: ImageType,
}

function Picture({ img }: PictureProps): React.Node {
  return (
    <picture className='rounded-[inherit] w-full'>
      <img
        className='rounded-[inherit] w-full'
        src={img.src}
        alt={img.alt}
        srcSet={img.srcset}
      />
    </picture>
  );
}

function Image({ className, img }: ImageProps): React.Node {
  const { containerRef, controls, variant: fadeVariants } = useAnimate(fade);

  return (
    <motion.div
      ref={containerRef}
      className={`aside_image rounded-3xl flex flex-col xl:w-full ${className ? className : ''}`}
      initial='hidden'
      animate={controls}
      variants={fadeVariants}
    >
      <Picture img={img} />
    </motion.div>
  );
}

export default Image;

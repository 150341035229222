// @flow
import * as React from 'react';
import { useMemo, useEffect } from 'react';
import { CaseStudy, CaseStudyInProgress } from './variants';

export const PROJECT_VARIANTS = {
  CASE_STUDY: 'case-study',
  IN_PROGRESS: 'in-progess',
};

type ButtonType = { 
  link: string,
  text: string,
  width: string,
}

type MediaType = {
  src: string,
  srcDark: string,
  alt: string,
}

export type MouseEventHandler = (event: SyntheticMouseEvent<HTMLDivElement>) => void;


export type ProjectType = {
  button: ButtonType,
  media: MediaType,
}

export type BadgeType = {
  label: string,
  message: string,
}

type ProjectProps = {
  variant?: string,
  project: ProjectType,
  badge?: BadgeType,
  render?: any, // sort out in pages & layouts branch
  hovered?: boolean,
  onMouseEnter?: MouseEventHandler,
  onMouseLeave?: MouseEventHandler
}

function Project({
  variant,
  project,
  badge,
  render,
  hovered,
  onMouseEnter,
  onMouseLeave,
}: ProjectProps): React.Node {
  if (variant === PROJECT_VARIANTS.IN_PROGRESS) {
    return <CaseStudyInProgress badge={badge} project={project} />;
  }

  return (
    <CaseStudy
      project={project}
      render={render}
      hovered={hovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

export default Project;

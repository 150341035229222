// @flow
import * as React from 'react';

import Search from 'assets/Steady_Paycheck/Search.png';
import Message from 'assets/Steady_Paycheck/message.svg';
import JobSeeker from 'assets/Steady_Paycheck/job-seeker.png';
import Courses from 'assets/Steady_Paycheck/course.svg';
import Jobs from 'assets/Steady_Paycheck/manage-jobs.png';
import Communication from 'assets/Steady_Paycheck/communication_wallpaper.png';

import Feature, { FEATURE_VARIANTS } from 'UI/Feature';

type ColType = {
  md?: number,
  xl?: number,
}

// Define types for feature properties
type FeatureImage = {
  src: string,
  alt: string,
  className?: string,
};

type FeatureContent = {
  label: string,
  paragraph: string,
};

type FeatureItem = {
  id: number,
  className: string,
  col: ColType,
  component: React.Node,
};

type ToggleClasses = (className: string, darkModeClassName: string) => string

type SetFeatures = (toggleClasses: ToggleClasses) => Array<FeatureItem>

export const setFeatures: SetFeatures = (toggleClasses) => {
  const features = [
    {
      id: 0,
      className: `border h-[500px] overflow-hidden rounded-3xl xl:row-span-2 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-12'
          content={{
            label: 'Empowering Your Search',
            paragraph:
              'Discover a comprehensive platform that simplifies your job search, enables seamless applications, and offers personalized recommendations tailored to your career goals.',
          }}
          img={{
            src: Search,
            alt: 'search-job',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 1,
      className: `border h-[500px] overflow-hidden rounded-3xl xl:row-span-2 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
        xl: 4,
      },
      component: (
        <Feature
          variant={FEATURE_VARIANTS.RIGHT}
          content={{
            label: 'Simplifying Job Search and Applications',
            paragraph:
              'Easily connect with potential employers, streamlining your job search and simplifying the application process.',
          }}
          img={{
            src: JobSeeker,
            alt: 'simplify-job-search',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 2,
      className: `border h-[500px] overflow-hidden rounded-3xl xl:row-span-2 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
        xl: 4,
      },
      component: (
        <Feature
          variant={FEATURE_VARIANTS.RIGHT}
          content={{
            label: 'Sharing Guidance and Expertise',
            paragraph:
              'This app empowers mentors to actively engage with aspiring professionals by providing valuable guidance and sharing their expertise.',
          }}
          img={{
            src: Message,
            alt: 'message-sharing',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 3,
      className: `border h-[500px] overflow-hidden rounded-3xl md:col-span-2 xl:col-span-2 xl:row-span-2 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
      },
      component: (
        <Feature
          reverse
          className='gap-4 lg:gap-6 [&>img]:w-fit'
          content={{
            label: 'Fostering Growth and Networking',
            paragraph:
              'This app facilitates mentorship programs, creating opportunities for professional growth and networking among users.',
          }}
          img={{
            src: Courses,
            alt: 'data-visualisation',
          }}
        />
      ),
    },
    {
      id: 4,
      className: `border h-[500px] overflow-hidden rounded-3xl xl:col-span-3 xl:row-span-2 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        xl: 3,
      },
      component: (
        <Feature
          className='gap-8 xl:gap-12'
          content={{
            label: 'Job Posting and Management',
            paragraph:
              'The app offers employers an intuitive interface for posting job openings and managing applicant profiles, simplifying the process of advertising and overseeing job listing.',
          }}
          img={{
            src: Jobs,
            alt: 'job-posting-and-management',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 5,
      className: `border h-[500px] overflow-hidden rounded-3xl xl:col-span-3 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        xl: 3,
      },
      component: (
        <Feature
          variant={FEATURE_VARIANTS.IMAGE}
          img={{
            src: Communication,
            alt: 'job-posting-and-management',
          }}
          reverse={false}
        />
      ),
    },
  ];

  return features;
};

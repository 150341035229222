// @flow
import * as React from 'react';

type ArrowUpProps = {
  darkMode?: boolean,
  stroke?: string,
}

function ArrowUp({ darkMode, stroke }: ArrowUpProps): React.Node {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
    >
      <path
        d='M4.5 1H10.5M10.5 1V7M10.5 1L0.965332 10.5343'
        stroke={
          darkMode
            ? `${stroke ? stroke : '#E0E1E3'}`
            : `${stroke ? stroke : '#090B11'}`
        }
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ArrowUp;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

import routes from './routes';
import './index.css';
import Cursor from 'Cursor';

import ThemeProvider from 'react-context/ThemeProvider';
import AuthProvider from 'react-context/AuthProvider';
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter(routes);

initializeApp({
  apiKey: 'AIzaSyCWSz3RdXUZIO5CxuEJSC_iQNPAw9vuGm8',
  authDomain: 'portfolio-database-2438e.firebaseapp.com',
  databaseURL: 'https://portfolio-database-2438e.firebaseio.com',
  projectId: 'portfolio-database-2438e',
  storageBucket: 'portfolio-database-2438e.appspot.com',
  messagingSenderId: '566492353952',
  appId: '1:566492353952:web:de8a8fa260344fec08c0c5',
  measurementId: 'G-4W3KQZ39EP',
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <ThemeProvider>
          <Cursor />
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signOut,
} from 'firebase/auth';
import { AuthContext } from './context';
export { AuthContext } from './context';

// Define the type for the user object
type UserType = {
  uid: string,
  displayName: ?string,
  email: ?string,
  // Add any other properties you might need
} | null;

// Define the props for AuthProvider
type AuthProviderProps = {
  children: React.Node,
};

// Define a type for the session timeout specific to the browser
type TimeoutType = TimeoutID | null;

function AuthProvider({ children }: AuthProviderProps): React.Node {
  const auth = getAuth();
  const [user, setUser] = useState<null>(null); // Specify UserType for user
  const [loading, setLoading] = useState<boolean>(true); // Loading state for Firebase initialization
  const [sessionTimeout, setSessionTimeout] = useState<TimeoutType>(null); // Use number | null for timeout ID

  useEffect(() => {
    let unsubscribe;

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Listen to auth state changes
        unsubscribe = onAuthStateChanged(auth, (currentUser) => {
          if (currentUser) {
            setUser(currentUser); // Assuming currentUser matches UserType
            startSessionTimer(); // Start session timer when user logs in
            console.log(currentUser, auth);
          } else {
            setUser(null);
            clearSessionTimer(); // Clear session timer when user logs out
          }
          setLoading(false); // Firebase has finished initializing
        });
      })
      .catch((error) => {
        console.error('Failed to set persistence', error);
        setLoading(false); // Stop loading even if persistence fails
      });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      clearSessionTimer(); // Clear session timer on component unmount
    };
  }, [auth]);

  // Start session timer (1 hour = 3600000 ms)
  const startSessionTimer = () => {
    clearSessionTimer(); // Clear any existing timer
    const timeout: TimeoutType = window.setTimeout(() => { // Use `window.setTimeout` in browser context
      handleSessionTimeout();
    }, 3600000); // 1 hour in milliseconds
    setSessionTimeout(timeout); // Set the timeout ID
  };

  // Clear the session timer
  const clearSessionTimer = () => {
    if (sessionTimeout !== null) { // Ensure sessionTimeout is not null
      clearTimeout(sessionTimeout); // This is valid as sessionTimeout is typed as number | null
      setSessionTimeout(null); // Reset sessionTimeout to null
    }
  };

  // Log the user out when session times out
  const handleSessionTimeout = () => {
    console.log('Session timed out. Logging out...');
    signOut(auth).catch((error) => console.error('Failed to sign out:', error));
  };

  // Show loading state while Firebase initializes
  if (loading) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export default AuthProvider;


// @flow
import * as React from 'react';
import Image from './base-components/Image';
import Main from './base-components/Main';
import type { ImageType, ContentType } from '.';

type SwitchAsideComponentType = (img?: ImageType , content?: ContentType ) => React.Node

export const switchAsideComponent: SwitchAsideComponentType = (img, content) => {
  return img ? (
    <Image img={img} />
  ) : (
    <Main
      title={content?.secondary?.title}
      paragraph={content?.secondary?.paragraph}
    />
  );
};

// @flow
import * as React from 'react';
import { Col } from 'Grid';

type ColType = {
  md?: number,
  xl?: number,
}

type BentoProps = {
  col: ColType,
  className?: string,
  children: React.Node  
}

function Bento({ col, className,children }: BentoProps): React.Node {
  return (
    <Col className={className} col={col}>
      {children}
    </Col>
  );
}

export default Bento;

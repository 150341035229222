// @flow
import * as React from 'react';
import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';
import { renderInformationComponent } from './utils';

export const INFORMATION_VARIANTS = {
  PARAGRAPH: 'PARAGRAPH',
  BULLET: 'BULLET',
  NUMBER: 'NUMBER',
};

export type ImageType = {
  src: string,
  alt: string,
  srcset?: string
}

export type ContentType = {
  title?: string,
  paragraph?: string,
  secondary?: SecondaryContentType,
  id?: number,
  value?: string
}

type SecondaryContentType = {
  title: string,
  paragraph: string,
}

type InformationProps = {
  variant?: string,
  contents?: Array<ContentType>,
  content?: ContentType
}

function Information({ variant, content, contents }: InformationProps): React.Node {
  const contentsArray = contents ? (Array.isArray(contents) ? contents : [contents]) : [];

  const component = renderInformationComponent(variant, content, contentsArray);

  return (
    <div className='info-section flex flex-col gap-4 lg:flex-row lg:gap-5'>
      <div className='info_title flex flex-col w-full lg:w-48'>
        <Headings heading={HEADING_VARIANTS.H3} title={content?.title} />
      </div>
      <div className='info_wrapper flex flex-col w-full'>
        <div className='info_content'>{component}</div>
      </div>
    </div>
  );
}

export default Information;

// @flow
import { useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { AuthContext } from 'react-context/AuthProvider';
import type { FirebaseAuthType, FirebaseUserType } from './types';

type AuthProps = {
  user?: FirebaseUserType | null,
  auth?: FirebaseAuthType, 
  signInWithUser?: (email: string, password: string) => Promise<any>, // Replace `any` with the correct type
  logOut?: () => Promise<void>,
}

export default function useAuth(): AuthProps {
  const auth = getAuth();
  const user = useContext(AuthContext);

  const signInWithUser = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const logOut = () => signOut(auth);

  return {
    user,
    auth,
    signInWithUser,
    logOut,
  };
}

// @flow
import * as React from 'react';
import type { VideoType } from '../..';

type VideoProps = {
  video?: VideoType,
  caption?: string 
}

function Video({ video, caption }: VideoProps): React.Node {
  return (
    <>
      <video
        className='h-full rounded-[inherit]'
        key={video?.videoKey}
        autoPlay
        muted
      >
        <source src={video?.src} type={video?.type} />
      </video>
      {caption && (
        <span className='text-gray-900 italic font-regular text-sm md:text-base'>
          {caption}
        </span>
      )}
    </>
  );
}

export default Video;


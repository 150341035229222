// @flow
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';
import type { HeadingType } from 'UI/Section/Heading';
import type { MediaType } from 'UI/Section/Media';
import type { ContentType } from 'UI/Section/Scenario';
import type { ContentType as InformationContentType } from 'UI/Section/Information';
import type { ContentType as AsideContentType, ImageType } from 'UI/Section/Aside';

export { INFORMATION_VARIANTS } from './Information';
export { MEDIA_VARIANTS } from './Media';


export const SECTION_VARIANTS = {
  ASIDE: 'ASIDE',
  HEADING: 'HEADING',
  INFORMATION: 'INFORMATION',
  MEDIA: 'MEDIA',
  SCENARIO: 'SCENARIO',
};

export type HeadingSectionProps = {
  showSubtitle: boolean,
  headings?: HeadingType,
  className?: string,
}

export type MediaSectionProps = {
  variant: string,
  media: MediaType,
  rounded?: boolean,
  animated?: boolean,
  className?: string,
}

export type ScenarioSectionProps = {
  content?: ContentType,
}

export type InformationSectionProps = {
  variant: string,
  contents?: Array<InformationContentType>,
  content?: InformationContentType
}

export type AsideSectionProps = {
  content: AsideContentType,
  img?: ImageType,
  reverse?: boolean,
}

type SectionProps = {
  variant: string,
  heading?: HeadingSectionProps,
  media?: MediaSectionProps,
  scenario?: ScenarioSectionProps,
  information?: InformationSectionProps,
  aside?: AsideSectionProps
}

function Section({ variant, heading, media, scenario, information, aside }: SectionProps): React.Node {
  const { component, renderVariants } = useComponent(variant);

  const variants = useMemo(
    () => setVariants(heading, aside, information, scenario, media),
    [heading, aside, information, scenario, media]
  );

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return <>{component}</>;
}

export default Section;

// @flow
import * as React from 'react';
import { renderComponent } from './utils';

type Icon = {
  leading: string,
  trailing?: string,
}

export type LinkType = {
  target?: string,
  to: string
}

export type ButtonType = {
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void | Promise<void>,
  type?: string,
  disabled?: boolean 
}

export type IconType = {
  isLeading: boolean | null,
  isTrailing?: boolean,
  feather?: boolean,
  icon?: Icon,
  stroke?: string,
  fill?: string,
  size?: string | number
}

export type ButtonProps = {
  variant?: string,
  link?: LinkType,
  button?: ButtonType,
  icon?: IconType,
  text: string | null,
  defaultWidth?: string,
  hovered?: boolean,
  className?: string,
  arrow?: boolean,
}

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ICON: 'icon',
  LINK: 'link',
};

function Button({
  variant,
  link,
  button,
  icon,
  text,
  defaultWidth,
  hovered,
  className,
  arrow,
}: ButtonProps): React.Node {
  const component = renderComponent(
    variant,
    link,
    button,
    icon,
    text,
    defaultWidth,
    hovered,
    className,
    arrow
  );

  return <>{component}</>;
}

export default Button;

// @flow
import * as React from 'react';
import Feature, { FEATURE_VARIANTS } from 'UI/Feature';

import Desktop from 'assets/Techrehab/Desktop.png';
import SingleDesign from 'assets/Techrehab/single_design.png';
import Mobile from 'assets/Techrehab/mobile.png';
import Illustration from 'assets/Techrehab/3D_Illustration.png';
import SideWallpaper from 'assets/Techrehab/side_wallpaper.svg';

type ColType = {
  md?: number,
  xl?: number,
}

// Define types for feature properties
type FeatureImage = {
  src: string,
  alt: string,
  className?: string,
};

type FeatureContent = {
  label: string,
  paragraph: string,
};

type FeatureItem = {
  id: number,
  className: string,
  col: ColType,
  component: React.Node,
};

type ToggleClasses = (className: string, darkModeClassName: string) => string

type SetFeatures = (toggleClasses: ToggleClasses) => Array<FeatureItem>

export const setFeatures: SetFeatures = (toggleClasses) => {
  const features = [
    {
      id: 0,
      className: `h-[500px] p-3 overflow-hidden rounded-3xl md:h-[600px] ${toggleClasses(
        'bg-[#8DB1FF]',
        'border-navy-600'
      )}`,
      col: {
        md: 4,
        xl: 3,
      },
      component: (
        <Feature
          className='gap-8 lg:gap-10'
          variant={FEATURE_VARIANTS.IMAGE}
          img={{
            src: SingleDesign,
            alt: 'search-job',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 1,
      className: `w-full h-[500px] overflow-hidden rounded-3xl xl:row-span-2 border ${toggleClasses(
        'bg-[#DDE3E6]',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
        xl: 2,
      },
      component: (
        <Feature
          className='gap-8 [&>*]:md:text-left'
          content={{
            label: 'Designing for Desktop',
            paragraph: ''
          }}
          img={{
            src: Desktop,
            alt: 'design-desktop',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 2,
      className: `w-full h-[500px] overflow-hidden rounded-3xl xl:row-span-2 border ${toggleClasses(
        'bg-[#9CBBFF]',
        'border-navy-600'
      )}`,
      col: {
        md: 2,
        xl: 1
      },
      component: (
        <Feature
          className='gap-8 text-white [&>*]:md:text-left'
          content={{
            label: 'Designing for Mobile',
            paragraph: ''
          }}
          img={{
            src: Mobile,
            alt: 'design-mobile',
          }}
          reverse={false}
        />
      ),
    },
    {
      id: 3,
      className: `border overflow-hidden pb-1.5 overflow-hidden rounded-3xl rounded-3xl xl:row-span-1 ${toggleClasses(
        'border-gray-600',
        'border-navy-600'
      )}`,
      col: {
        md: 4,
        xl: 1
      },
      component: (
        <Feature
          className='gap-8 justify-center items-center text-center'
          content={{
            label: '3D Illustration',
            paragraph: ''
          }}
          img={{
            src: Illustration,
            alt: 'illustration-3D',
          }}
          reverse
        />
      ),
    },
    {
      id: 4,
      className: `overflow-hidden rounded-3xl ${toggleClasses(
        'bg-[#9CBBFF]',
        'border border-navy-600'
      )}`,
      col: {
        md: 4,
        xl: 2
      },
      component: (
        <Feature
          className='gap-8 lg:gap-10'
          variant={FEATURE_VARIANTS.IMAGE}
          img={{
            src: SideWallpaper,
            alt: 'search-job',
          }}
          reverse={false}
        />
      ),
    },
  ];

  return features;
};

// @flow
export const hover = (hover: string | void): string => (hover ? hover : '');
export const focus = (focus: string | void): string => (focus ? focus : '');
export const error = (error: string | void): string => (error ? 'border-error-100' : 'border-gray-600');

export const inputClasses = {
  light: `border-gray-600 ${hover('hover:border-brand-500')} ${focus(
    'focus:border-brand-500'
  )}`,

  dark: `border-navy-500 ${hover('hover:border-brand-400')} ${focus(
    'focus:border-brand-400'
  )}`,
};
// @flow
import * as React from 'react';

type ContainerProps = {
  children: React.Node,
  className?: string,
  full?: boolean
}

function Container({ children, className, full }: ContainerProps): React.Node {
  return (
    <div className={`container ${className ? className : ''} ${full ? 'w-full' : 'w-full px-2 mx-auto md:px-6 lg:max-w-192 lg:px-[5rem]'}`}>
      {children}
    </div>
  );
}

export default Container;

// @flow
import * as React from 'react';
import Input from 'Shared-UI/Input';

type IconNameType = {
  leading: string,
  trailing?: string,
}

type IconClickType = {
  leading: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,
  trailing?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,
}

type IconType = {
  isLeading: boolean,
  isTrailing?: boolean,
  feather?: boolean,
  icon: IconNameType,
  stroke?: string,
  fill?: string,
  size?: string | number,
  click: IconClickType
}

type InputValueType = string | number | $ReadOnlyArray<string>;

type InputType = { 
  placeholder?: string,
  onChange?: SyntheticInputEvent<HTMLInputElement>,
  name?: string,
  value?: InputValueType,
  type: string
}

export type FormControlProps = {
  input: InputType,
  icon?: IconType,
  error: string
}

function FormControl({ input, icon, error }: FormControlProps): React.Node {
  return (
    <div className='form-control w-full flex flex-col gap-1.5'>
      <Input
        err={error} 
        icon={icon}
        input={input}
      />
      <p className={`pl-2 text-base text-error-300`}>{error}</p>
    </div>
  );
}

export default FormControl;

// @flow
import * as React from 'react';
import { useState, useEffect } from 'react';
import Icon from 'Icons';
import useTheme from 'react-hooks/useTheme';
 
function Switch(): React.Node {
  const [isChecked, setIsChecked] = useState(false);
  const { darkMode, toggleMode } = useTheme();

  useEffect(() => {
    setIsChecked(darkMode);
  }, [darkMode]);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
    toggleMode();
  };

  return (
    <label
      htmlFor='check'
      className={`relative flex w-10 h-7 rounded-full cursor-pointer ${
        isChecked ? 'bg-navy-500' : 'bg-grayblue-200'
      }`}
    >
      <input
        type='checkbox'
        id='check'
        className='sr-only peer'
        checked={isChecked}
        onChange={toggleSwitch}
      />

      <div className='flex flex-row w-[4.25rem] h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center pl-0.5 h-full w-full z-10'>
          <Icon icon='sun' isEnabled={isChecked} />
        </div>
        <div className='flex flex-col justify-center items-center pl-0.5 h-full w-full z-10'>
          <Icon icon='moon' isEnabled={isChecked} />
        </div>
      </div>
      <span
        className={`absolute w-6 h-6 rounded-full left-0.5 top-0.5 bg-brand-500 peer-checked:bg-brand-400 peer-checked:left-[2.25rem] transition-all duration-500`}
      ></span>
    </label>
  );
}

export default Switch;

// @flow
import * as React from 'react';
import Image from './base-components/Image';
import Video from './base-components/Video';

import type { ImageType, VideoType } from '.';
import { MEDIA_VARIANTS } from '.';

type RenderMediaComponentType = (variant?: string, img?: ImageType, video?: VideoType, caption?: string) => React.Node;


export const renderMediaComponent: RenderMediaComponentType = (variant, img, video, caption) => {
  let component: React.Node = null;

  switch (variant) {
    case MEDIA_VARIANTS.IMAGE:
      component = <Image img={img} caption={caption} />;
      break;
    case MEDIA_VARIANTS.VIDEO:
      component = <Video video={video} caption={caption} />;
      break;
    default:
      break;
  }

  return component;
};

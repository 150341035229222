// @flow
import * as React from 'react';
import { useEffect, useMemo } from 'react';

import useAnimate from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';

export const HERO_VARIANTS = {
  PRIMARY: 'PRIMARY',
  LANDING: 'LANDING',
};

type ImageType = {
  src: string,
  alt: string,
  srcset?: string, 
}

export type ProjectType = {
  img: ImageType
}

type HeroProps = {
  variant?: string,
  project?: ProjectType,
  className?: string,
}

function Hero({ variant, project, className }: HeroProps): React.Node {
  const { controls, containerRef } = useAnimate();
  const { component, renderVariants } = useComponent(variant);

  const variants = useMemo(() => {
    return setVariants(controls, project);
  }, [controls, project]);

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return (
    <div
      ref={containerRef}
      className={`hero flex flex-col gap-6 rounded-xl md:rounded-3xl md:gap-7 lg:gap-8 justify-center items-center ${className ? className : ''}`}
    >
      {component}
    </div>
  );
}

export default Hero;

// @flow
import * as React from 'react';
import { motion } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useAnimate, { reveal } from 'react-hooks/useAnimate';
import type { ContentType } from '../..';


type NumberProps = {
  contents?: Array<ContentType>
}

function Number({ contents }: NumberProps): React.Node {
  const { toggleClasses } = useComponent();
  const { containerRef, controls, variant: infoVariants } = useAnimate(reveal);

  return (
    <ol
      className='list-decimal flex flex-col gap-1.5 overflow-hidden md:ml-2'
      ref={containerRef}
    >
      {contents?.map((content) => (
        <motion.li
          key={content?.id}
          animate={controls}
          initial='hidden'
          variants={infoVariants}
          className={`text-lg leading-9 ${toggleClasses(
            'text-navy-900',
            'text-gray-700'
          )}`}
        >
          {content?.value}
        </motion.li>
      ))}
    </ol>
  );
}

export default Number;

// @flow
import * as React from 'react';
import Information from './Information';
import Scenario from './Scenario';
import Aside from './Aside';
import Heading from './Heading';
import Media from './Media';
import { SECTION_VARIANTS } from '.';
import type { HeadingSectionProps, MediaSectionProps, ScenarioSectionProps, InformationSectionProps, AsideSectionProps} from '.'


type SectionType = $Values<typeof SECTION_VARIANTS>;

type VariantType = {
  [key: SectionType]: () => React.Node,
  default?: () => React.Node,
};

type setVariantsType = (heading?: HeadingSectionProps, aside?: AsideSectionProps, information?: InformationSectionProps, scenario?: ScenarioSectionProps, media?: MediaSectionProps) => VariantType;

export const setVariants: setVariantsType = (heading, aside, information, scenario, media) => {
  const variants: VariantType = {
    [SECTION_VARIANTS.HEADING]: () => (
      <Heading
        showSubtitle={heading?.showSubtitle}
        headings={heading?.headings}
        className={heading?.className}
      />
    ),
    [SECTION_VARIANTS.MEDIA]: () => (
      <Media
        variant={media?.variant}
        media={media?.media}
        animated={media?.animated}
        className={media?.className}
        rounded={media?.rounded}
      />
    ),
    [SECTION_VARIANTS.INFORMATION]: () => (
      <Information
        variant={information?.variant}
        contents={information?.contents}
        content={information?.content}
      />
    ),
    [SECTION_VARIANTS.SCENARIO]: () => <Scenario content={scenario?.content} />,
    [SECTION_VARIANTS.ASIDE]: () => (
      <Aside
        content={aside?.content}
        img={aside?.img}
        reverse={aside?.reverse}
      />
    ),
  };

  variants['default'] = variants[SECTION_VARIANTS.HEADING];

  return variants;
};

// @flow
import * as React from 'react';
import useComponent from 'react-hooks/useComponent';
import { DropdownItem } from './utils';

type Icon = {
  leading: string,
  trailing?: string,
}

export type LinkType = {
  target?: string,
  to?: string
}

export type IconType = {
  isLeading: boolean,
  isTrailing?: boolean,
  feather?: boolean,
  icon?: Icon,
  stroke?: string,
  fill?: string,
  size?: string | number
}

export type DropdownItemType = {
  id?: number,
  text: string,
  link?: LinkType,
  icon?: IconType
}

type DropdownProps = {
  links: Array<DropdownItemType>
}

function Dropdown({ links }: DropdownProps): React.Node {

  const { toggleClasses } = useComponent()

  return (
    <div className={`dropdown flex flex-col p-1.5 pb-3 rounded-2xl border  ${toggleClasses('bg-gray-50 border-gray-600', 'bg-navy-900 border-navy-600')}`}>
      {links.map((link) => (
        <DropdownItem text={link.text} link={link.link} icon={link.icon} />
      ))}
    </div>
  );
}
export default Dropdown;

import { Main, Login, Bubbly, SteadyPayCheck, Techrehab } from 'Pages';
import Switch from 'Shared-UI/Switch';
import SmoothScroll from 'SmoothScroll';

const routes = [
  {
    path: '/',
    element: (
      <>
        <SmoothScroll>
          <Main />
        </SmoothScroll>
        <div className='fixed bottom-2 right-2 z-2'>
          <Switch />
        </div>
      </>
    ),
  },
  {
    path: '/login',
    element: (
      <>
        <SmoothScroll>
          <Login />
        </SmoothScroll>
        <div className='fixed bottom-2 right-2 z-2'>
          <Switch />
        </div>
      </>
    ),
  },
  {
    path: '/bubbly',
    element: (
      <>
        <SmoothScroll>
          <Bubbly />
        </SmoothScroll>
        <div className='fixed bottom-2 right-2 z-2'>
          <Switch />
        </div>
      </>
    ),
  },
  {
    path: '/steadypaycheck',
    element: (
      <>
        <SmoothScroll>
          <SteadyPayCheck />
        </SmoothScroll>
        <div className='fixed bottom-2 right-2 z-2'>
          <Switch />
        </div>
      </>
    ),
  },
  {
    path: '/techrehab',
    element: (
      <>
        <SmoothScroll>
          <Techrehab />
        </SmoothScroll>
        <div className='fixed bottom-2 right-2 z-2'>
          <Switch />
        </div>
      </>
    ),
  },
];

export default routes;

// @flow
import { useRef, useEffect } from 'react';
import { useAnimation, useInView } from 'framer-motion';

type VariantState = {
  opacity?: number,
  y?: string | number,
  transition?: {
    duration: number,
    ease: Array<number> | string,
    delay?: number,
  },
};

type VariantType = {
  hidden: VariantState,
  visible: VariantState,
};

type UseAnimateReturnType = {
  controls: any, // Replace with correct framer-motion AnimationControls type if available
  containerRef: { current: null | HTMLElement },
  variant?: VariantType,
};

export const reveal = {
  hidden: {
    opacity: 0,
    y: '100%',
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5,
      ease: [0.77, 0, 0.175, 1],
      delay: 0.5,
    },
  },
};

export const fade = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, ease: 'easeIn' },
  },
};

function useAnimate(variant?: VariantType): UseAnimateReturnType {
  const containerRef = useRef<null | HTMLElement>(null);
  const controls = useAnimation();
  const isInView = useInView(containerRef, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  return {
    controls,
    containerRef,
    variant,
  };
}

export default useAnimate;

// @flow
import * as React from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import useAuth from 'react-hooks/useAuth';

import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

import Form from 'UI/Form';

import { validationSchema, errorMessages, formValues } from './schema';
import { LoginIllustration, renderInputFields } from './utils';

function Login(): React.Node {
  const { signInWithUser } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: (data) => {
      const { email, password } = data;
      handleSignIn(email, password);
    },
  });

  const inputs = renderInputFields(formik, showPassword, setShowPassword);

  async function handleSignIn(email: string, password: string) {
    if (signInWithUser) {
      signInWithUser(email, password)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        const customErrMessage =
          errorMessages[error.code] ||
          'An unexpected error occured. Please try again';
        setError(customErrMessage);
      });
    }
  }

  return (
    <div className='login__container h-screen flex flex-col items-center px-2 md:justify-between lg:flex-row lg:p-3'>
      <div className='login__form flex flex-col gap-9 pt-20 md:pt-0 lg:w-56 lg:h-full lg:pt-24 lg:items-center xl:w-64'>
        <div className='login-form__header flex flex-col items-center text-center gap-1 lg:px-8'>
          <h1 className='font-semibold text-3xl'>Hello There!</h1>
          <p className='font-semibold text-base'>
            Want to see private projects. Enter login credentials below.
          </p>
        </div>

        <div className='login-form__container flex flex-col items-center gap-5 lg:px-8'>
          <Form
            handleSubmit={formik.handleSubmit}
            error={error}
            inputs={inputs}
            button={{
              text: 'Continue',
              icon: {
                isLeading: false,
                isTrailing: false,
              },
              disabled: !(formik.isValid && formik.dirty),
            }}
          />

          <Button
            variant={BUTTON_VARIANTS.LINK}
            className='text-base text-brand-500'
            link={{ to: 'mailto:azini@live.co.uk', target: '_blank' }}
            text='Request Credientials'
            icon={{
              isLeading: false,
              isTrailing: false,
            }}
          />
        </div>
      </div>

      <LoginIllustration />
    </div>
  );
}

export default Login;

// @flow
import * as React from 'react';
import { Badge, BadgeDot, BadgeMessage } from './variants';
import { BADGE_VARIANTS } from '.';
import type { IconType } from '.';

type BadgeType = $Values<typeof BADGE_VARIANTS>;

type VariantType = {
  [key: BadgeType]: () => React.Node,
  default?: () => React.Node,
};

type setVariantType = (label?: string, icon?: IconType, message?: string) => VariantType;

export const setVariants: setVariantType = (label, icon, message) => {
  const variants: VariantType = {
    [BADGE_VARIANTS.BADGE]: () => <Badge label={label} />,
    [BADGE_VARIANTS.DOT]: () => <BadgeDot label={label} icon={icon} />,
    [BADGE_VARIANTS.MESSAGE]: () => (
      <BadgeMessage label={label} icon={icon} message={message} />
    ),
  };

  variants['default'] = variants[BADGE_VARIANTS.BADGE];

  return variants;
};

// @flow
import * as React from 'react';
import useComponent from 'react-hooks/useComponent';
import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';

export type HeadingType = {
  title?: string,
  subtitle?: string,
  heading: string,
  className?: string
}

type HeadingProps = {
  showSubtitle?: boolean,
  headings?: HeadingType,
  className?: string
}

function Heading({ showSubtitle = false, headings, className }: HeadingProps): React.Node {
  const { toggleClasses } = useComponent();

  return (
    <div
      className={`heading-section flex flex-col gap-2 w-full ${
        className ? className : ''
      }`}
    >
      {showSubtitle && (
        <Headings
          heading={HEADING_VARIANTS.H5}
          title={headings?.subtitle}
          className={`${toggleClasses('text-brand-500', 'text-brand-400')}`}
        />
      )}
      <Headings
        heading={headings?.heading}
        title={headings?.title}
        className={headings?.className}
      />
    </div>
  );
}

export default Heading;

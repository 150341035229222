// @flow
import * as React from 'react';
import Paragraph from './base-components/Paragraph';
import Bullet from './base-components/Bullet-List';
import Number from './base-components/Number-List';
import type { ContentType } from '.';

import { INFORMATION_VARIANTS } from '.';

type SetVariantType = (variant?: string, content?: ContentType, contents?: Array<ContentType>) => React.Node;

export const renderInformationComponent: SetVariantType = (variant, content, contents) => {
  let component: React.Node = null

  switch (variant) {
    case INFORMATION_VARIANTS.PARAGRAPH:
      component = <Paragraph content={content} />;
      break;
    case INFORMATION_VARIANTS.BULLET:
      component = <Bullet contents={contents} />;
      break;
    case INFORMATION_VARIANTS.NUMBER:
      component = <Number contents={contents} />;
      break;

    default:
      break;
  }

  return component;
};

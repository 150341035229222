// @flow
import * as React from 'react';

type LinkType = {
  target?: string,
  to: string
}

type ButtonType = {
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void | Promise<void>,
  type?: string,
  disabled?: boolean 
}

type ActionType = {
  link?: LinkType,
  button?: ButtonType,
  className?: string,
  children: React.Node
}

function Action({ link, button, className, children }: ActionType): React.Node {
  if (link) {
    return (
      <a
        href={link.to}
        target={link.target ? link.target : null}
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <button className={className} onClick={button?.onClick} type={button?.type} disabled={button?.disabled}>
      {children}
    </button>
  );
}

export default Action;

// @flow
import * as React from 'react';
import Main from './Main';
import Project from './Project';
import type { ContentType as ScenarioContentType } from 'UI/Section/Scenario'
import type { ProjectType as HeroProjectType } from 'UI/Hero'

export const LAYOUT_VARIANTS = {
  PRIMARY: 'PRIMARY',
  PROJECT: 'PROJECT',
};

type MetaTagType = {
  id: number,
  name: string,
  content: string,
}

export type MetaType = {
  title: string,
  tags: Array<MetaTagType>
}

export type HeroType = {
  variant: string,
  project?: HeroProjectType,
  className?: string,
  title?: string,
  subtitle?: string
}

export type ProjectType = {
  name: string
}

export type ScenarioType = {
  content: ScenarioContentType
}

type LayoutProps = {
  variant: string,
  meta: MetaType,
  hero: HeroType,
  project?: ProjectType,
  scenario?: ScenarioType,
  children: React.Node
}

function Layout({ variant, meta, hero, project, scenario, children }: LayoutProps): React.Node {
  let component: React.Node = null;

  switch (variant) {
    case LAYOUT_VARIANTS.PRIMARY:
      component = <Main children={children} hero={hero} meta={meta} />;
      break;
    case LAYOUT_VARIANTS.PROJECT:
      component = (
        <Project
          children={children}
          hero={hero}
          project={project}
          scenario={scenario}
          meta={meta}
        />
      );
      break;
    default:
      component = <Main meta={meta} hero={hero} children={children} />;
  }

  return <>{component}</>;
}

export default Layout;

// @flow
import * as React from 'react';
import { motion } from 'framer-motion';
import useComponent from 'react-hooks/useComponent';
import useAnimate, { reveal } from 'react-hooks/useAnimate';

type HeadingProps = {
  title: string | React.Node,
  animate: any,
  className?: string
}

export function H1({ title, animate, className }: HeadingProps): React.Node {
  const { toggleClasses: toggle } = useComponent();

  return (
    <motion.h1
      initial='hidden'
      className={`font-bold text-3xl leading-tight md:text-4xl lg:text-5xl ${toggle(
        `${className ? className : 'text-navy-900'}`,
        `${className ? className : 'text-gray-600'}`
      )}`}
      animate={animate}
      variants={reveal}
    >
      {title}
    </motion.h1>
  );
}

export function H2({ title, animate, className }: HeadingProps): React.Node {
  const { toggleClasses: toggle } = useComponent();

  return (
    <motion.h2
      initial='hidden'
      className={`font-bold text-2xl leading-tight md:text-3xl lg:text-4xl ${toggle(
        `${className ? className : 'text-navy-900'}`,
        `${className ? className : 'text-gray-600'}`
      )}`}
      animate={animate}
      variants={reveal}
    >
      {title}
    </motion.h2>
  );
}

export function H3({ title, animate, className }: HeadingProps): React.Node {
  const { toggleClasses: toggle } = useComponent();

  return (
    <motion.h3
      initial='hidden'
      className={`text-xl font-semibold md:text-2xl lg:text-3xl ${toggle(
        `${className ? className : 'text-navy-900'}`,
        `${className ? className : 'text-gray-600'}`
      )}`}
      animate={animate}
      variants={reveal}
    >
      {title}
    </motion.h3>
  );
}

export function H4({ title, animate, className }: HeadingProps): React.Node {
  const { toggleClasses: toggle } = useComponent();

  return (
    <motion.h4
      initial='hidden'
      className={`text-lg font-semibold md:text-xl lg:text-2xl ${toggle(
        `${className ? className : 'text-navy-900'}`,
        `${className ? className : 'text-gray-600'}`
      )}`}
      animate={animate}
      variants={reveal}
    >
      {title}
    </motion.h4>
  );
}

export function H5({ title, animate, className }: HeadingProps): React.Node {
  const { toggleClasses: toggle } = useComponent();

  return (
    <motion.h5
      initial='hidden'
      className={`text-base font-medium md:text-lg ${toggle(
        `${className ? className : 'text-navy-900'}`,
        `${className ? className : 'text-gray-600'}`
      )}`}
      animate={animate}
      variants={reveal}
    >
      {title}
    </motion.h5>
  );
}

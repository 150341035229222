// @flow
type ToggleClassesType = (lightModeClass: string, darkModeClass: string) => string;

type Icon = {
  leading: string,
  trailing?: string,
}

type LinkType = {
  target?: string,
  to?: string
}

type IconType = {
  isLeading: boolean,
  isTrailing?: boolean,
  feather?: boolean,
  icon?: Icon,
  stroke?: string,
  fill?: string,
  size?: string | number
}

type DropdownItemType = {
  id?: number,
  text: string,
  link?: LinkType,
  icon?: IconType
}

type LinksArrayType = (toggleClasses: ToggleClassesType, dropdown: boolean) => Array<DropdownItemType> 

const firebaseCVLink =
  'https://firebasestorage.googleapis.com/v0/b/portfolio-database-2438e.appspot.com/o/Alex%20Zietara%20Nicholls.pdf?alt=media&token=bdd568e5-ce1b-4462-9872-27704dc58ae6';

export const linksArray: LinksArrayType = (toggleClasses, dropdown) => {
  const links = [
    {
      id: 0,
      text: 'Projects',
      link: {
        to: '/',
      },
      icon: {
        isLeading: dropdown,
        feather: false,
        stroke: toggleClasses('black', 'white'),
        icon: {
          leading: 'arrow-up'
        }
      },
    },
    {
      id: 1,
      text: 'Resume',
      link: {
        to: firebaseCVLink,
        target: '_blank', // Use single underscore for target (should be "_blank")
      },
      icon: {
        isLeading: dropdown,
        feather: false,
        stroke: toggleClasses('black', 'white'),
        icon: {
          leading: 'arrow-up'
        }
      },  
    },
    {
      id: 2,
      text: 'Contact',
      link: {
        to: 'mailto:azini@live.co.uk',
      },
      icon: {
        isLeading: dropdown,
        feather: false,
        stroke: toggleClasses('black', 'white'),
        icon: {
          leading: 'arrow-up'
        }
      },
    },
  ];

  return links;
};
// @flow
import * as React from 'react';
import { Reorder } from 'framer-motion';
import { colVariants } from './variants';
import type { ProjectType } from 'Pages/Main/projects';

export type CSSProperties = Partial<$Diff<CSSStyleDeclaration, { '@@iterator': mixed }>>

type ColProps = {
  col: any,
  animated?: boolean, 
  className?: string,
  children: React.Node,
  style?: CSSProperties,
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void | Promise<void>,
  drag?: boolean,
  value?: ProjectType,
};

function Col({ col, animated, className, children, style, onClick, drag, value }: ColProps): React.Node {
  // Map col props to the respective Tailwind classes
  const columnClassNames = [
    col.sm ? colVariants.sm[col.sm] : '', // col.sm could be undefined, safe check
    col.md ? colVariants.md[col.md] : '',
    col.lg ? colVariants.lg[col.lg] : '',
    col.xl ? colVariants.xl[col.xl] : '',
  ].filter(Boolean).join(' ');

  if (animated) {
    return (
      <Reorder.Item
        className={`${columnClassNames} ${className || ''}`}
        drag={drag}
        value={value}
        onClick={onClick}
      >
        {children}
      </Reorder.Item>
    );
  } else {
    return (
      <div className={`${columnClassNames} ${className || ''}`} style={style} onClick={onClick}>
        {children}
      </div>
    );
  }
}

export default Col;

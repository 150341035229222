// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'react-hooks/useAuth';

type Props = {|
  [key: string]: any,
|};

type AuthContextType = {|
  user?: any, // Replace `any` with the correct user type if known
  auth?: any, // Firebase auth object type if needed
  signInWithUser?: (email: string, password: string) => Promise<any>, // Type the method accordingly
  logOut?: () => Promise<void>,
|};

// Define the type alias for the withProtected HOC
type WithProtectType = <P: Props>(Child: React.ComponentType<P>) => React.ComponentType<P>;

const withProtected: WithProtectType = <P: Props>(Child: React.ComponentType<P>): React.ComponentType<P> => {
  function Component(props: P): React.Node {
    const navigate = useNavigate();
    const { user }: AuthContextType = useAuth();

    useEffect(() => {
      if (!user) {
        navigate('/login');
      }
    }, [user, navigate]);

    return <Child {...props} />;
  }

  return Component;
};

export default withProtected;

// @flow
import * as React from 'react';
import useTheme from 'react-hooks/useTheme';

import loginIllustrationDark from './../../assets/login_illustration--dark.mp4';
import loginIllustrationLight from './../../assets/login_illustration--light.mp4';

type VideoProps = {
  videoKey?: string,
  src: string,
}

type FormikValues = {
  email: string,
  password: string,
}

type FormikTouched = {
  email: boolean,
  password: boolean,
}

type FormikErrors = {
  email: string,
  password: string,
}

type FormikType = {
  values: FormikValues,
  errors: FormikErrors,
  touched: FormikTouched,
  handleChange: (e: SyntheticInputEvent<HTMLInputElement>) => void
}

type SetShowPassword = (boolean | (boolean => boolean)) => void;

type SetRenderInputFieldsType = (formik: FormikType, showPassword: boolean, setShowPassword: SetShowPassword) => any;

function Video({ videoKey, src }: VideoProps): React.Node {
  return (
    <video
      key={videoKey}
      className='w-full h-full object-cover object-center'
      autoPlay
      muted
    >
      <source src={src} />
    </video>
  );
}

export function LoginIllustration(): React.Node {
  const { darkMode } = useTheme();

  return (
    <div className='login__illustration relative h-full w-full xl:max-w-[2800px] rounded-3xl hidden lg:flex'>
      <div className='project relative w-full h-full overflow-hidden rounded-[inherit]'>
        <div className='project_image absolute left-0 top-0 w-full h-full'>
          {darkMode ? (
            <Video videoKey='dark-mode-video' src={loginIllustrationDark} />
          ) : (
            <Video videoKey='light-mode-video' src={loginIllustrationLight} />
          )}
        </div>
      </div>
    </div>
  );
}

export const renderInputFields: SetRenderInputFieldsType = (formik, showPassword, setShowPassword) => {
  const inputs = [
    {
      input: {
        name: 'email',
        type: 'text',
        value: formik.values.email,
        placeholder: 'Email',
        onChange: formik.handleChange,
      },
      error: formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null,
      icon: {
          isLeading: false,
      },
    },
    {
      input: {
        name: 'password',
        type: showPassword ? 'text' : 'password',
        value: formik.values.password,
        placeholder: 'Password',
        onChange: formik.handleChange,
      },
      error: formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null,
      icon: {
        isLeading: true,
        feather: true,
        icon: { 
          leading: showPassword ? 'eye-off' : 'eye',
        },
        stroke: formik.errors.password
            ? 'text-error-300'
            : 'text-brand-500',
        click: {
          leading: () => setShowPassword((prev) => !prev)
        }
      },
    },
  ];

  return inputs;
};

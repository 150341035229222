// @flow
import * as React from 'react';
import { useMemo, useEffect } from 'react';
import useAnimate, { reveal } from 'react-hooks/useAnimate';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';

type HeadingsProps = {
  heading?: string, 
  title: string | React.Node,
  className?: string
}

export const HEADING_VARIANTS = {
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
};

function Headings({ heading, title, className }: HeadingsProps): React.Node{
  const { containerRef, controls, variant } = useAnimate(reveal);
  const { component, renderVariants } = useComponent(heading);

  const headings = useMemo(
    () => setVariants(controls, title, className),
    [controls, title, className]
  );

  useEffect(() => {
    renderVariants(headings);
  }, [headings, renderVariants]);

  return (
    <div
      ref={containerRef}
      className={`${className ? className : ''} reveal-heading overflow-hidden`}
    >
      {component}
    </div>
  );
}

export default Headings;

// @flow
import * as React from 'react';
import { motion } from 'framer-motion';

import useAnimate, { fade } from 'react-hooks/useAnimate';
import { renderMediaComponent } from './utils';

export type ImageType = {
  src: string,
  alt: string
}

export type VideoType = {
  src: string,
  alt: string,
  type?: string,
  videoKey?: string
}

export const MEDIA_VARIANTS = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export type MediaType = {
  img?: ImageType,
  video?: VideoType,
  caption?: string
}

type MediaProps = { 
  variant?: string,
  media?: MediaType,
  rounded?: boolean,
  animated?: boolean,
  className?: string,
}

function Media({ variant, media, rounded = false, animated, className }: MediaProps): React.Node {
  const { containerRef, controls, variant: animateVariant } = useAnimate(fade);

  console.log(rounded);

  const component = renderMediaComponent(
    variant,
    media?.img,
    media?.video,
    media?.caption
  );

  if (animated) {
    return (
      <motion.div
        ref={containerRef}
        className={`media-section lg:p-2 ${
          rounded ? 'rounded-xl md:rounded-2xl lg:rounded-3xl' : ''
        } ${className ? className : ''}`}
        initial='hidden'
        animate={controls}
        variants={animateVariant}
      >
        {component}
      </motion.div>
    );
  }

  return (
    <div
      className={`media-section ${
        rounded ? 'rounded-xl md:rounded-2xl lg:rounded-3xl' : ''
      } ${className ? className : ''}`}
    >
      {component}
    </div>
  );
}

export default Media;

// @flow
import * as React from 'react';
import {
  check,
  checkEnter,
  handleProjectEnter,
  handleProjectLeave,
} from './helpers';
import type { ProjectType as ItemType } from './projects';

type RenderProject = (project: ItemType, darkMode: boolean) => React.Node;

type SetState<T> = (T) => void;

type FilterProjects = (setSelectedCategory: SetState<string>, selectedCategory: string, setHoverState: SetState<{[key:string]: boolean}>, hoverState: {[key: string]: boolean}, setItems: SetState<Array<ItemType>>, items: Array<ItemType>) => any

export const renderProject: RenderProject = (project, darkMode) => {
  if (project.media.type === 'img') {
    return (
      <picture>
        <img
          className='w-full h-full object-cover object-center'
          src={`${darkMode ? project.media.srcDark : project.media.src}`}
          alt={`${project.button?.text || ''} thumbnail`}
        />
      </picture>
    );
  } else {
    return darkMode ? (
      <video
        key='dark-mode-project-video'
        className='w-full h-full object-cover object-center'
        onMouseEnter={handleProjectEnter}
        onMouseLeave={handleProjectLeave}
        loop
      >
        <source src={project.media.srcDark} />
      </video>
    ) : (
      <video
        key='light-mode-project-video'
        className='w-full h-full object-cover object-center'
        onMouseEnter={handleProjectEnter}
        onMouseLeave={handleProjectLeave}
        loop
      >
        <source src={project.media.src} />
      </video>
    );
  }
};

export const filterProjects: FilterProjects = (
  setSelectedCategory,
  selectedCategory,
  setHoverState,
  hoverState,
  setItems,
  items
) => {
  const filterItems = (category: string) => {
    setSelectedCategory(category);
  };

  const handleReorder = (reorderedItems: Array<ItemType>) => {
    setItems(reorderedItems);
  };

  const getFilteredItems = () => {
    if (selectedCategory === 'All') return items;

    const filtered = items.filter((item) => item.category === selectedCategory);
    const nonFiltered = items.filter(
      (item) => item.category !== selectedCategory
    );

    return [...filtered, ...nonFiltered];
  };

  const checkCategory = (item: ItemType) => check(item, selectedCategory);

  const checkCategoryEnter = (item: ItemType) =>
    checkEnter(item, selectedCategory, hoverState, setHoverState);

  return {
    filterItems,
    handleReorder,
    getFilteredItems,
    checkCategory,
    checkCategoryEnter,
  };
};

// @flow
import * as React from 'react';
import { motion } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useAnimate, { reveal } from 'react-hooks/useAnimate';
import type { ContentType , FactType } from '../..';

type FactProps = {
  fact: FactType,
  controls: any
}

type ContentProps = {
  content: ContentType
}

function Fact({ fact, controls }: FactProps): React.Node {
  const {toggleClasses: toggle} = useComponent();
  const { variant: animateVariants } = useAnimate(reveal);

  return (
    <li className='scenario__fact text-lg flex flex-row gap-2 overflow-hidden'>
      <motion.label
        variants={animateVariants}
        initial='hidden'
        animate={controls}
        className={`leading-9 font-medium ${toggle(
          'text-navy-500',
          'text-gray-500'
        )}`}
      >
        {fact.label}
      </motion.label>
      <motion.p
        variants={animateVariants}
        animate={controls}
        initial='hidden'
        className={`leading-9 ${toggle('text-navy-500', 'text-gray-700')}`}
      >
        {fact.role}
      </motion.p>
    </li>
  );
}

function Content({ content }: ContentProps): React.Node {
  const {
    containerRef,
    controls,
    variant: animateVariants,
  } = useAnimate(reveal);
  const { toggleClasses } = useComponent();

  return (
    <div className='scenario__content flex flex-col gap-4 md:gap-6'>
      <div className='scenario__paragraph overflow-hidden' ref={containerRef}>
        <motion.p
          variants={animateVariants}
          animate={controls}
          initial='hidden'
          className={`text-lg leading-9 ${toggleClasses(
            'text-navy-500',
            'text-gray-700'
          )}`}
        >
          {content.paragraph}
        </motion.p>
      </div>
      <ul
        className='scenario__facts overflow-hidden flex flex-col gap-4 md:flex-row'
        ref={containerRef}
      >
        {content.facts?.map((fact, index) => (
          <Fact
            key={index}
            fact={fact}
            controls={controls}
          />
        ))}
      </ul>
    </div>
  );
}

export default Content;

// @flow
export const contacts = [
  {
    icon: '📧',
    channel: 'azini@live.co.uk',
    link: 'mailto:azini@live.co.uk',
  },
  {
    icon: '🔵',
    channel: 'LinkedIn',
    link: 'https://www.linkedin.com/in/alex-ziet%C3%A1ra-nicholls/',
  },
];

export const socials = [
  {
    icon: '🎨',
    channel: 'Behance',
    link: 'https://www.behance.net/azini6326',
  },
  {
    icon: '🟣',
    channel: 'Dribbble',
    link: 'https://dribbble.com/AlexNicholls1999',
  },
  {
    icon: '📷',
    channel: 'Instagram',
    link: 'https://www.instagram.com/azini_ui/',
  },
  {
    icon: '📝',
    channel: 'Medium',
    link: 'https://medium.com/@azini10001',
  },
];

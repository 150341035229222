// @flow
import * as React from 'react';

type MoonIconProps = {
  isEnabled?: boolean,
}

function MoonIcon({ isEnabled }: MoonIconProps): React.Node {
  return (
    <svg
      width='26'
      height='27'
      viewBox='0 0 26 27'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={isEnabled ? '#FCFCFC' : '#9DA9B4'}
        d='M19.5092 15.7448C18.1836 18.9037 14.7874 20.6494 11.4584 19.8831C8.12947 19.1167 5.83096 16.0602 6.00975 12.6373C6.18853 9.21444 8.79287 6.41576 12.1834 6.00292C12.3579 5.98202 12.5262 6.07478 12.6023 6.23369C12.6819 6.38979 12.652 6.57959 12.5284 6.70348C11.5113 7.71604 10.9456 9.09832 10.9597 10.536C10.9744 12.4385 11.9831 14.1938 13.6166 15.1595C15.2502 16.1252 17.2689 16.1597 18.9343 15.2503C19.0898 15.1698 19.2786 15.1926 19.4106 15.308C19.5227 15.4234 19.5608 15.5922 19.5092 15.7448Z'
      />
    </svg>
  );
}

export default MoonIcon;

// @flow
import * as React from 'react';
import useComponent from 'react-hooks/useComponent';
import { NavItem } from './utils';

type Icon = {
  leading: string,
  trailing?: string,
}

export type LinkType = {
  to?: string,
  target?: string
}

export type IconType = {
  isLeading: boolean,
  isTrailing?: boolean,
  feather?: boolean,
  icon?: Icon,
  stroke?: string,
  fill?: string,
  size?: string | number
}

type NavItemLinkType = {
  id?: number,
  text: string,
  link?: LinkType,
  icon?: IconType
}

type NavLinkProps = {
  links: Array<NavItemLinkType>
}

function Nav({ links }: NavLinkProps): React.Node {
  const { toggleClasses } = useComponent();

  return (
    <nav className='nav hidden flex-row md:flex'>
      <ul className='nav__list-items flex flex-row gap-2 md:gap-4'>
        {links.map((link) => (
          <NavItem
            key={link?.id}
            link={{
              to: link?.link?.to,
              target: link?.link?.target,
            }}
            icon={{ isLeading: false }}
            text={link?.text}
            toggle={toggleClasses}
          />
        ))}
      </ul>
    </nav>
  );
}

export default Nav;

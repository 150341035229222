// @flow
import * as React from 'react';
import { createContext } from 'react';

type ThemeContextType = {
  darkMode: boolean,
  toggleMode: () => void,
};

// Create the context with the correct type and default value
export const ThemeContext: React$Context<ThemeContextType> = createContext({
    darkMode: false,
    toggleMode: () => {},
});

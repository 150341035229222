// @flow
import * as React from 'react';
import useAnimate, { reveal } from 'react-hooks/useAnimate';

import Headings, { HEADING_VARIANTS } from 'Shared-UI/Headings';

import Content from './Components/Content';
import Links from './Components/Links';


export type FactType = {
  label?: string,
  role?: string,
}

export type ContentType = {
  paragraph?: string,
  facts?: Array<FactType>,
  links?: Array<LinkType>
}

export type LinkType = {
  id: number,
  url: string,
  title: string,
}

export type AnimationControls = {
  start: (animation: { [key: string]: any }) => Promise<void>,
  stop: () => void,
  set: (animation: { [key: string]: any }) => void,
};

type ScenarioProps = {
  content?: ContentType,
}

function Scenario({ content }: ScenarioProps): React.Node {
  const { controls } = useAnimate(reveal);

  return (
    <div className='scenario flex flex-col gap-4 md:flex-col xl:flex-row xl:gap-5'>
      <div className='scenario__title w-full lg:w-48'>
        <Headings heading={HEADING_VARIANTS.H3} title='Scenario' />
      </div>
      <div className='scenario_container flex flex-col gap-5 md:gap-7 w-full'>
        <Content
          content={{
            paragraph: content?.paragraph,
            facts: content?.facts,
          }}
        />

        <Links links={content?.links}  />
      </div>
    </div>
  );
}

export default Scenario;

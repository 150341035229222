// @flow
import * as React from 'react';
import Main from './base-components/Main';
import { switchAsideComponent } from './utils';

export type ImageType = {
  src: string,
  alt: string,
  srcset?: string
}

export type ContentType = {
  title: string,
  paragraph: string,
  secondary?: SecondaryContentType
}

type SecondaryContentType = {
  title: string,
  paragraph: string,
}

type AsideProps = {
   content?: ContentType,
   img?: ImageType,
   reverse?: boolean,
}

function Aside({ content, img, reverse }: AsideProps): React.Node {
  const rowClass = reverse
    ? 'flex-col lg:flex-row-reverse'
    : 'flex-col lg:flex-row';

  return (
    <div
      className={`aside-section flex gap-6 ${rowClass} lg:items-center lg:justify-center xl:gap-12`}
    >
      <Main title={content?.title} paragraph={content?.paragraph} />
      {switchAsideComponent(img, content)}
    </div>
  );
}

export default Aside;

// @flow
import * as React from 'react';
import Icon from 'Icons';
import type { LinkType, IconType } from '.';

type ToggleClassesType = (lightModeClass: string, darkModeClass: string) => string;

type NavItemProps = {
  text: string,
  link?: LinkType,
  icon?: IconType,
  toggle: ToggleClassesType
}


export function NavItem({ link, icon, text, toggle }: NavItemProps): React.Node {
  return (
    <a
      href={link?.to}
      target={link?.target}
      className='nav__list-item flex flex-row justify-center items-center hover:opacity-50'
    >
      <div className='list-item__container flex flex-row gap-1.5 justify-center items-center'>
        {icon?.isTrailing && (
          <div className='list-item__icon h-4 w-4 grid justify-center items-center'>
            <Icon
              feather={icon?.feather}
              stroke={icon?.stroke}
              icon={`${icon?.icon?.leading ? icon.icon.leading : 'plus'}`}
            />
          </div>
        )}

        <div className='list-item__content w-full'>
          <label
            className={`content__label font-medium text-sm hover:opacity-50 cursor-default md:text-base ${toggle(
              'text-navy-900',
              'text-gray-700'
            )}`}
          >
            {text}
          </label>
        </div>

        {icon?.isLeading && (
          <div className='list-item__icon h-4 w-4 grid justify-center items-center'>
            <Icon
              feather={icon?.feather}
              stroke={icon?.stroke}
              icon={`${icon?.icon?.leading ? icon.icon.leading : 'plus'}`}
            />
          </div>
        )}
      </div>
    </a>
  );
}

// @flow
import * as React from 'react';
import { useMemo, useEffect, useState } from 'react';
import { ThemeContext } from './context';
export { ThemeContext } from './context';

type ThemeProviderProps = {
  children: React.Node
};

function ThemeProvider({ children }: ThemeProviderProps): React.Node {
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const toggleMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode)); // Save the new value to localStorage
      return newMode;
    });
  };

  // Check localStorage for darkMode preference when the component mounts
  useEffect(() => {
    const savedMode: ?string = localStorage.getItem('darkMode');
    
    if (savedMode !== null) {
      try {
        setDarkMode(JSON.parse(savedMode || 'false')); // Provide a default fallback if savedMode is null
      } catch (error) {
        console.error('Error parsing darkMode from localStorage', error);
      }
    }

    setIsLoading(false); // Set loading to false once the check is complete
  }, []);

  const theme = useMemo(() => ({ darkMode, toggleMode }), [darkMode]);

  // Only render the children when loading is complete
  if (isLoading) {
    return null; // Or you could render a loading spinner if needed
  }

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}

export default ThemeProvider;

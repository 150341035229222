// @flow
import * as React from 'react';
import { motion } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useAnimate, { reveal } from 'react-hooks/useAnimate';
import type { ContentType } from '../..';

type ParagraphProps = { 
  content?: ContentType
}

function Paragraph({ content }: ParagraphProps): React.Node {
  const { toggleClasses } = useComponent();
  const { containerRef, controls, variant: infoVariants } = useAnimate(reveal);

  return (
    <div className='paragraph overflow-hidden' ref={containerRef}>
      <motion.p
        animate={controls}
        initial='hidden'
        variants={infoVariants}
        className={`text-lg leading-9 ${toggleClasses(
          'text-navy-900',
          'text-gray-700'
        )}`}
      >
        {content?.paragraph}
      </motion.p>
    </div>
  );
}

export default Paragraph;

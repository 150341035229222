// @flow
import * as React from 'react';
import { useState, useCallback } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import useTheme from 'react-hooks/useTheme'

type UseComponentReturnType = {
  component: React.Node,
  renderVariants: (variants: RenderVariantsType) => void,
  toggle: (lightmode: string, darkmode: string) => string,
  toggleClasses: (className: string, darkModeClassName: string) => string,
}

type VariantFunction = () => React.Node; // Replace with the actual return type if known

type VariantsType = {
    default?: VariantFunction; // A function that returns a React component
    [key: string]: VariantFunction | void; // Additional properties can be functions returning React components
};

type RenderVariantsType = {
    default?: any; // You can replace `any` with the specific type you expect
    [key: string]: any; // This allows for additional properties of any type
};

export default function useComponent(variant?: string): UseComponentReturnType {
  const [component, setComponent] = useState(null);
  const { darkMode } = useTheme();

  const classnames = useCallback((...args: any) => {
    return twMerge(clsx(args));
  }, []);

  const toggleClasses = useCallback(
    (className: string, darkModeClassName: string) => {
      return classnames(className, {
        [darkModeClassName]: darkMode,
      });
    },
    [darkMode, classnames]
  );

  const toggle = useCallback(
    (lightmode: string, darkmode: string) => {
      return darkMode ? darkmode : lightmode;
    },
    [darkMode]
  );

  // Updates variants

  const updateVariant = useCallback(
    (variants: any) => {
      if (variants && variants['default']) {
        setComponent(variants['default']());
      } else {
        console.error('Default variant not found in variants object.');
      }

      if (variants && variants[variant]) {
        setComponent(variants[variant]);
      } else {
        console.log(`Component variant ${variant ?? 'undefined'} not found`);
      }
    },
    [variant]
  );

  // Renders variant after check

  const renderVariants = useCallback(
    (variants: RenderVariantsType) => {
      if (variants && variants['default']) {
        updateVariant(variants);
      } else {
        console.error('Default variant not found in variants object.');
      }
    },
    [updateVariant]
  );

  return {
    component,
    renderVariants,
    toggle,
    toggleClasses,
  };
}

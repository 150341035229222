// @flow
import * as React from 'react';
import AvatarMe from 'assets/avatar/mini-me.svg';

type AvatarProps = { 
  height: string | number,
  width: string | number
}

function Avatar({ height, width }: AvatarProps): React.Node {
  return <img src={AvatarMe} height={height} width={width} />
}

export default Avatar;

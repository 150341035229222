// @flow
import Font from 'assets/Techrehab/Font.png';
import AIIllustration from 'assets/Techrehab/rebrand_ai-illustration.png';
import Service from 'assets/Techrehab/Service.png';
import Process from 'assets/Techrehab/Process.png';

import type { ContentType as BrandContentType, ImageType } from 'UI/Section/Aside';
import type { FactType } from 'UI/Section/Scenario'

export type BrandType = {
  id: number,
  content: BrandContentType,
  img?: ImageType,
  reverse?: boolean,
}

export const facts: Array<FactType> = [
  {
    label: 'Role',
    role: 'UI Designer',
  },
  {
    label: 'Duration',
    role: '8 Weeks',
  },
  {
    label: 'Project',
    role: 'Rebranding Tech Project',
  },
];

export const brandings: Array<BrandType> = [
  {
    id: 0,
    content: {
      title: 'AI Generated 3D',
      paragraph:
        'The incorporation of AI-generated 3D illustrations through Midjourney added a cutting-edge touch.',
    },
    img: {
      src: AIIllustration,
      alt: '',
    },
    reverse: false,
  },
  {
    id: 1,
    content: {
      title: 'Satoshi Font',
      paragraph:
        'Choosing Satoshi font ensures a modern, readable, and globally appealing design. Reflecting our commitment to clarity, professionalism and a minimalistic design.',
    },
    img: {
      src: Font,
      alt: '',
    },
    reverse: true,
  },
  {
    id: 2,
    content: {
      title: 'New Logo',
      paragraph:
        'TechRehabs new logo mirrors its values and services. Closure was used to represent different devices the company provides services.',
    },
    img: {
      src: Service,
      alt: '',
    },
    reverse: false,
  },
  {
    id: 3,
    content: {
      title: 'Logo Process',
      paragraph:
        'The redesigned logo retains the familiar squared style, honouring the companys visual heritage while effectively conveying its brand identity.',
    },
    img: {
      src: Process,
      alt: '',
    },
    reverse: true,
  },
];

// @flow
import * as React from 'react';
import Channel from './Channel';
import { socials, contacts } from './utils';

function Media(): React.Node {
  return (
    <div className='media w-full flex flex-col gap-4 lg:w-128'>
      <div className='media__contacts flex flex-col w-full h-fit gap-4 md:flex-row'>
        {contacts.map((icon, index) => (
          <Channel
            key={index}
            icon={icon.icon}
            channel={icon.channel}
            link={icon.link}
          />
        ))}
      </div>

      <div className='media__socials flex flex-col w-full h-fit gap-4 md:flex-row'>
        {socials.map((icon, index) => (
          <Channel
            key={index}
            icon={icon.icon}
            channel={icon.channel}
            link={icon.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Media; 

// @flow
import * as React from 'react';
import FeatherIcon from 'feather-icons-react';
import ArrowUp from './Arrow-Up';
import Moon from './Moon';
import Sun from './Sun';

type IconProps = {
  icon: any,
  stroke?: string,
  feather?: boolean,
  isEnabled?: boolean,
  darkMode?: boolean,
  fill?: string,
  size?: string | number,
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void 
}

type HandleIconVariantType = (variant: string, stroke?: string, isEnabled?: boolean, darkMode?: boolean) => React.Node

const handleIconVariant: HandleIconVariantType = (variant, stroke, isEnabled, darkMode) => {
  let icon: React.Node = null;

  switch (variant) {
    case 'arrow-up':
      icon = <ArrowUp stroke={stroke} darkMode={darkMode} />;
      break;
    case 'moon':
      icon = <Moon isEnabled={isEnabled} />;
      break;
    case 'sun':
      icon = <Sun isEnabled={isEnabled} />;
      break;
    default:
      break;
  }

  return icon;
};

function Icon({ icon, stroke, feather, isEnabled, darkMode, size, fill, onClick }: IconProps): React.Node {
  const customIcon = handleIconVariant(icon, stroke, isEnabled, darkMode);

  return <>{feather ? <FeatherIcon onClick={onClick} size={size} fill={fill} icon={icon} /> : customIcon}</>;
}

export default Icon;

// @flow
import * as React from 'react';
import Icon from 'Icons';
import useComponent from 'react-hooks/useComponent';
import { inputClasses, focus, error } from 'Shared-UI/Input/helpers'

type IconNameType = {
  leading: string,
  trailing?: string,
}

type IconClickType = {
  leading: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,
  trailing?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void,
}

type IconType = {
  isLeading: boolean,
  isTrailing?: boolean,
  feather?: boolean,
  icon: IconNameType,
  stroke?: string,
  fill?: string,
  size?: string | number,
  click: IconClickType
}

type InputValueType = string | number | $ReadOnlyArray<string>;

type InputType = { 
  placeholder?: string,
  onChange?: SyntheticInputEvent<HTMLInputElement>,
  name?: string,
  value?: InputValueType,
  type: string
}

export type InputProps = {
  icon?: IconType,
  err: string,
  input: InputType,
}


function Input({ icon, err, input }: InputProps ): React.Node {
  const { toggleClasses } = useComponent();

  return (
    <div
      className={`input p-2 rounded-2xl flex flex-row items-center border border-solid ${error(
        err
      )} ${toggleClasses(inputClasses.light, inputClasses.dark)}`}
    >
      {icon?.isTrailing ? (
        <Icon
          feather={icon?.feather}
          stroke={icon?.stroke}
          icon={`${icon?.icon.trailing ? icon.icon.trailing : 'plus'}`}
          size='16'
        />
      ) : null}
      <input
        className={`input input__base w-full bg-transparent outline-0 ${focus(
          'focus:outline-none'
        )} ${toggleClasses('placeholder-navy-300', 'placeholder-gray-200')} `}
        autoComplete='new-password'
        name={input.name}
        placeholder={input.placeholder}
        onChange={input.onChange}
        value={input.value}
        type={input.type}
      />
      {icon?.isLeading ? (
        <Icon
          onClick={icon?.click.leading}
          feather={icon?.feather}
          stroke={icon?.stroke}
          icon={`${icon?.icon.leading ? icon.icon.leading : 'plus'}`}
          size='16'
        />
      ) : null}
    </div>
  );
}

export default Input;

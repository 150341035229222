// @flow
import { useContext, useEffect } from 'react';
import { ThemeContext } from 'react-context/ThemeProvider';

type ThemeType = {
  darkMode: boolean,
  toggleMode: any
}

function useTheme(): ThemeType {
  const theme = useContext(ThemeContext);

  if (!theme) {
    throw new Error('Theme context must be used inside the ThemeProvider');
  }

  useEffect(() => {
    const root = document.body;

    // Define the full class string for both themes
    const darkModeClass = 'bg-navy-900 text-gray-50 transition-background duration-2000 scrollbar--dark';
    const lightModeClass = 'bg-gray-50 text-navy-700 transition-background duration-2000 scrollbar--light';


    if (root) {
      root.className = theme.darkMode ? darkModeClass : lightModeClass
    }


    // Cleanup function to reset className
    return () => {
      if (root) {
        root.className = '';
      }
    };
  }, [theme.darkMode]);

  return theme;
}

export default useTheme;
// @flow
import * as React from 'react';
import { useMemo, useEffect } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';

export const BADGE_VARIANTS = {
  BADGE: 'BADGE',
  DOT: 'BADGE-DOT',
  MESSAGE: 'BADGE-MESSAGE',
};

export type IconType = {
  icon: string,
  feather?: boolean,
  stroke?: string,
  fill?: string,
  size?: number
}

export type BadgeProps = {
  variant?: string,
  label?: string,
  icon?: IconType,
  message?: string
}

function Badge({ variant, label, icon, message }:BadgeProps ): React.Node {
  const { component, renderVariants, toggleClasses } = useComponent(variant);

  const variants = useMemo(
    () => setVariants(label, icon, message),
    [label, icon, message]
  );

  useEffect(() => {
    renderVariants(variants);
  }, [variants, renderVariants]);

  return (
    <div
      className={`badge rounded-full flex flex-row justify-center items-center gap-2 px-4 py-1.5 ${toggleClasses(
        'bg-grayblue-300',
        'bg-navy-900'
      )}`}
    >
      {component}
    </div>
  );
}

export default Badge;

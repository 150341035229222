// @flow
import * as React from 'react';
import { useEffect } from 'react';
import useComponent from 'react-hooks/useComponent';
import { setVariants } from './utils';

export type ImageType = { 
  src: string,
  alt: string,
  className?: string
}

export type ContentType = {
  label: string,
  paragraph: string,
}

export type FeatureProps = {
  variant?: string,
  img?: ImageType,
  content?: ContentType,
  reverse?: boolean,
  className?: string
}

export const FEATURE_VARIANTS = {
  CENTRE: 'CENTRE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  IMAGE: 'IMAGE',
};

function Feature({ variant, img, content, reverse, className }: FeatureProps): React.Node {
  const { component, renderVariants } = useComponent(variant);

  const variants = setVariants(content, img, reverse, className);

  useEffect(() => {
    renderVariants(variants);
  }, []);

  return component;
}

export default Feature;

// @flow
import * as React from 'react';
import { ButtonIcon, ButtonPrimary, ButtonSecondary, Link } from './variants';
import { BUTTON_VARIANTS} from '.';
import type { ButtonProps, LinkType, ButtonType, IconType } from '.';

type setComponentType = (variant?: string, link?: LinkType, button?: ButtonType, icon?: IconType, text: string | null, defaultWidth?: string, hovered?: boolean, className?: string, arrow?: boolean) => React.Node;

export const renderComponent: setComponentType = (
  variant,
  link,
  button,
  icon,
  text,
  defaultWidth,
  hovered,
  className,
  arrow
) => {
  let variants: React.Node = null;

  switch (variant) {
    case BUTTON_VARIANTS.PRIMARY:
      variants = (
        <ButtonPrimary
          link={link}
          icon={icon}
          text={text}
          className={className}
          button={button}
        />
      );
      break;
    case BUTTON_VARIANTS.SECONDARY:
      variants = (
        <ButtonSecondary link={link} icon={icon} text={text} button={button} />
      );
      break;
    case BUTTON_VARIANTS.ICON:
      variants = (
        <ButtonIcon
          icon={icon}
          arrow={arrow}
          link={link}
          text={text}
          hovered={hovered}
          defaultWidth={defaultWidth}
          button={button}
        />
      );
      break;
    case BUTTON_VARIANTS.LINK:
      variants = (
        <Link
          link={link}
          icon={icon}
          text={text}
          className={className}
        />
      );
      break;
    default:
      break;
  }

  return variants;
};

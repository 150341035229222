// @flow
import * as React from 'react';
import FeatureCentre from './__base-components/FeatureCentre';
import FeatureRight from './__base-components/FeatureRight';
import FeatureLeft from './__base-components/FeatureLeft';
import FeatureImage from './__base-components/FeatureImage';
import type { ImageType, ContentType } from '.';
import { FEATURE_VARIANTS } from ".";

type FeatureType = $Values<typeof FEATURE_VARIANTS>;

type VariantType = {
  [key: FeatureType]: () => React.Node,
  default?: () => React.Node
}

type setVariantType = (content?: ContentType, img?: ImageType, reverse?: boolean, className?: string) => VariantType;

export const setVariants: setVariantType = (content, img, reverse, className) => {
  const variants: VariantType = {
    [FEATURE_VARIANTS.CENTRE]: () => (
      <FeatureCentre
        content={content}
        img={img}
        reverse={reverse}
        className={className}
      />
    ),
    [FEATURE_VARIANTS.LEFT]: () => (
      <FeatureLeft content={content} img={img} reverse={reverse} />
    ),
    [FEATURE_VARIANTS.RIGHT]: () => (
      <FeatureRight content={content} img={img} reverse={reverse} />
    ),
    [FEATURE_VARIANTS.IMAGE]: () => (
      <FeatureImage content={content} img={img} reverse={reverse} />
    ),
  };

  variants['default'] = variants[FEATURE_VARIANTS.CENTRE];

  return variants;
};

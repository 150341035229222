// @flow
import * as React from 'react';
import Container from 'Grid/Container';

import Switch from 'Shared-UI/Switch';

import Footer from 'UI/Footer';
import Header from 'UI/Header';
import Hero from 'UI/Hero';

import { Meta } from 'Pages';

import type { HeroType, MetaType } from 'Layout';

type MainLayoutProps = {
  meta: MetaType,
  hero: HeroType,
  children: React.Node
}

function Main({ meta, hero, children }: MainLayoutProps): React.Node {
  return (
    <>
      <Meta title={meta.title} tags={meta.tags} />

      <Container>
        <Header />
        <Hero variant={hero.variant} />
      </Container>

      <main className='main'>{children}</main>

      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default Main;

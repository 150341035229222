// @flow
import type { ProjectType as ItemType } from "./projects";

type HoverStateType = { [key: string]: boolean };

type CheckEnter = (
  item: ItemType,
  selectedCategory: string,
  hoverState: HoverStateType,
  setHoverState: (HoverStateType) => void
) => void;

type Check = (item: ItemType, selectedCategory: string) => string;

export const handleProjectEnter = (e: SyntheticEvent<HTMLVideoElement>) => {
  const projectVid: HTMLVideoElement = (e.currentTarget: HTMLVideoElement);
  projectVid.muted = true;

  if (projectVid.paused) {
    projectVid.play().catch((error) => {
      console.error('Error playing video:', error);
    });
  }
};

export const handleProjectLeave = (e: SyntheticEvent<HTMLVideoElement>) => {
  const projectVid: HTMLVideoElement = (e.currentTarget: HTMLVideoElement);
  projectVid.muted = false;
  projectVid.currentTime = 0;

  if (!projectVid.paused) {
    projectVid.pause();
  }
};

export const check: Check = (item, selectedCategory) =>
  selectedCategory !== 'All' && item.category !== selectedCategory
    ? 'opacity-20'
    : '';

export const checkEnter: CheckEnter = (
  item,
  selectedCategory,
  hoverState,
  setHoverState
) => {
  if (selectedCategory !== 'All' && item.category !== selectedCategory) {
    setHoverState({ ...hoverState, [String(item.id)]: false });
  } else {
    setHoverState({ ...hoverState, [String(item.id)]: true });
  }
};

// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import Section, { SECTION_VARIANTS } from 'UI/Section';
import Media from 'UI/Media';
import { HEADING_VARIANTS } from 'Shared-UI/Headings';

const getYear = (year: (number) => void) => year(new Date().getFullYear());

function Footer(): React.Node {
  const [year, setYear] = useState<number>(0);

  useEffect(() => {
    getYear(setYear);
  }, []);

  return (
    <footer className='footer flex flex-col justify-center items-center gap-11 pb-16'>
      <div className='footer__media flex flex-col justify-center items-center gap-7 md:gap-7 lg:gap-9 text-center'>
        <Section
          variant={SECTION_VARIANTS.HEADING}
          heading={{
            headings: {
              heading: HEADING_VARIANTS.H2,
              title: 'Open to new projects, ideas or opportunities',
              subtitle: 'Lets Talk',
            },
            showSubtitle: true,
            className: '[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
          }}
        />
        <Media />
      </div>
      <p className='footer__copyright justify-center'>
        &copy;{year}, Alex Zietara-Nicholls
      </p>
    </footer>
  );
}

export default Footer;

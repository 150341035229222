// @flow
import * as React from 'react';
import { LandingHero, MainHero } from './variants';

import AppleEmojiLight from 'assets/avatar/apple-memoji-animation.svg';
import AppleEmojiDark from 'assets/avatar/apple-memoji-animation--dark.svg';

import { HERO_VARIANTS } from '.';
import type { ProjectType } from '.';

type HeroType = $Values<typeof HERO_VARIANTS>;

type VariantType = {
  [key: HeroType]: () => React.Node,
  default?: () => React.Node,
}

type AppleEmojiType = {
  isDarkMode: boolean
}

type SetVariantType = (controls: any, project?: ProjectType) => VariantType;

export const firebaseCVLink =
  'https://firebasestorage.googleapis.com/v0/b/portfolio-database-2438e.appspot.com/o/Alex%20Zietara%20Nicholls.pdf?alt=media&token=bdd568e5-ce1b-4462-9872-27704dc58ae6'

export const AppleEmoji = ({ isDarkMode }: AppleEmojiType): React.Node => {
  return isDarkMode ? (
    <img src={AppleEmojiDark} alt="apple-emoji-dark" className='w-full h-32 w-32 md:w-40 md:h-40' />
  ) : (
    <img src={AppleEmojiLight} alt="apple-emoji-light" className='w-full h-32 w-32 md:w-40 md:h-40' />
  );
};

export const setVariants: SetVariantType = (controls, project) => {
  const variants: VariantType = {
    [HERO_VARIANTS.PRIMARY]: () => <MainHero project={project} />,
    [HERO_VARIANTS.LANDING]: () => <LandingHero controls={controls} />,
  };

  variants['default'] = variants[HERO_VARIANTS.PRIMARY];

  return variants;
};

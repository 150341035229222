// @flow
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Scroll from 'react-smooth-scrollbar';

type ScrollbarRef = {
  scrollbar: {
    setPosition: (x: number, y: number) => void
  }
}

type SmoothScrollProps = {
  children: React.Node
}

function SmoothScroll({ children }: SmoothScrollProps): React.Node {
  const ref = useRef<ScrollbarRef | null>(null);
  const scrollContentRef = useRef<?HTMLDivElement | null>(null); // Create a new ref for the scroll content
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollContentRef.current) {
      // Use ref instead of querying the DOM
      scrollContentRef.current.className = 'pfl-view flex flex-col gap-16';
    }

    return () => {
      if (scrollContentRef.current) {
        scrollContentRef.current.className = '';
      }
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const { scrollbar } = ref.current;
      scrollbar.setPosition(0, 0);
    }
  }, [pathname]);

  return (
    <Scroll className='pfl-view' ref={ref} damping={0.09}>
      <div ref={scrollContentRef}>{children}</div>
      {/* Assign ref to the div containing the children */}
    </Scroll>
  );
}

export default SmoothScroll;

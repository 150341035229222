// @flow
import * as React from 'react';
import type { ImageType } from '../..';

type ImageProps = {
  img?: ImageType,
  caption?: string
}

function Image({ img, caption }: ImageProps): React.Node {
  return (
    <>
      <picture className="rounded-[inherit]">
        <img className='w-full rounded-[inherit]' src={img?.src} alt={img?.alt} />
      </picture>
      {caption && (
        <span className='text-gray-900 italic font-regular text-sm md:text-base'>
          {caption}
        </span>
      )}
    </>
  );
}

export default Image;
// @flow
import * as React from 'react';
import { motion } from 'framer-motion';

type Animate = {
    x?: number,
    y?: number,
    opacity?: number,
    scale?: number,
    rotate?: number,
    width?: number | string | Array<number | string>,
    [key: string]: number | string | Array<number | string> | void
}

type Initial = {
    x?: number,
    y?: number,
    opacity?: number,
    scale?: number,
    rotate?: number,
    width?: number | string | Array<number | string>,
    [key: string]: number | string | Array<number | string> | void
}

type LinkType = {
  target?: string,
  to: string
}

type ButtonType = {
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void | Promise<void>,
  type?: string,
  disabled?: boolean 
}

type AnimateActionType = {
  link?: LinkType,
  button?: ButtonType,
  className?: string,
  children: React.Node,
  animate?: Animate,
  initial?: Initial
}

function AnimateAction({ link, button, className, children, initial, animate }: AnimateActionType): React.Node {
  if (link) {
    return (
      <motion.a
        href={link.to}
        target={link.target ? link.target : null}
        className={className}
        animate={animate}
        initial={initial}
      >
        {children}
      </motion.a>
    );
  }

  return (
    <motion.button className={className} onClick={button?.onClick} type={button?.type} disabled={button?.disabled} animate={animate} initial={initial}>
      {children}
    </motion.button>
  );
}

export default AnimateAction;

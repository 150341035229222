// @flow
import * as React from 'react';
import FormControl from 'Shared-UI/FormControl';
import { ServerMessage } from './utils';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';
import type { FormControlProps as FormControlType } from 'Shared-UI/FormControl';

type Icon = {
  leading: string,
  trailing?: string,
}

type IconType = {
  isLeading: boolean | null,
  isTrailing?: boolean,
  feather?: boolean,
  icon?: Icon,
  stroke?: string,
  fill?: string,
  size?: string | number
}

type ButtonType = {
  text: string,
  icon: IconType,
  disabled?: boolean
}

type FormProps = {
  handleSubmit: (event: SyntheticEvent<HTMLFormElement>) => void,
  inputs: Array<FormControlType>,
  error: string | null,
  button: ButtonType
}

function Form({ handleSubmit, inputs, error, button }: FormProps): React.Node {
  return (
    <form
      className='form flex flex-col items-center w-full gap-6 md:w-48'
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      {error && <ServerMessage error={error} />}
      <div className='form__inputs w-full flex flex-col gap-3'>
        {inputs.map(({ input, icon, error }: FormControlType, index: number) => (
          <FormControl key={index} input={input} icon={icon} error={error} />
        ))}
      </div>

      <Button
        variant={BUTTON_VARIANTS.PRIMARY}
        className='w-full flex flex-col justify-center py-2'
        text={button.text}
        icon={button.icon} 
        button={{
          type: 'submit',
          disabled: button.disabled
        }}
      />
    </form>
  );
}

export default Form;

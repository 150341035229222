// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';

type UsePortalType = {
  root: HTMLElement | null,
  Portal: (props: { children: React.Node, root?: HTMLElement | null }) => React.Node,
  createElement: (eId: string) => HTMLElement,
};

export default function usePortal(id?: string): UsePortalType {
  const root = id ? document.getElementById(id) : null;

  const portal = (element: React.Node, root: HTMLElement | null) => createPortal(element, root);

  const createElement = (eId: string) => {
    const element = document.createElement('div');
    element.setAttribute('id', eId);
    if (document.body) {
      document.body.appendChild(element);
    } else {
      console.error('Document body is not available');
    }
    return element;
  };

  function Portal({ children, root: customRoot }: { children: React.Node, root?: HTMLElement | null }) {
    return portal(children, customRoot || root);
  }

  return {
    root,
    Portal,
    createElement,
  };
}
// @flow
import * as React from 'react';
import { H1, H2, H3, H4, H5 } from './headings';
import { HEADING_VARIANTS } from '.';

type HeadingType = $Values<typeof HEADING_VARIANTS>;

type VariantType = {
  [key: HeadingType]: () => React.Node,
  default?: () => React.Node
}

type setVariantType = (controls: any, title: string | React.Node, className?: string) => VariantType;

export const setVariants: setVariantType = (controls, title, className) => {
  const headings: VariantType = {
    [HEADING_VARIANTS.H1]: () => (
      <H1
        title={title}
        className={className}
        animate={controls}
      />
    ),
    [HEADING_VARIANTS.H2]: () => (
      <H2
        title={title}
        className={className}
        animate={controls}
      />
    ),
    [HEADING_VARIANTS.H3]: () => (
      <H3
        title={title}
        className={className}
        animate={controls}
      />
    ),
    [HEADING_VARIANTS.H4]: () => (
      <H4
        title={title}
        className={className}
        animate={controls}
      />
    ),
    [HEADING_VARIANTS.H5]: () => (
      <H5
        title={title}
        className={className}
        animate={controls}
      />
    ),
  };

  headings['default'] = headings[HEADING_VARIANTS.H1];

  return headings;
};
